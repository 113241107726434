import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import Typist from 'react-typist';
import 'react-typist/dist/Typist.css';
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <h1>
    <Typist className='my-typist' style={{
        display: 'inline-block !important'
      }} mdxType="Typist">
        Hello, I'm
        Antwan Sherif
    </Typist>
    </h1>
    <p>{`I create exciting web and mobile app experiences for the next generation of consumer-facing companies`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      