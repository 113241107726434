import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const ProjectCard = makeShortcode("ProjectCard");
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h2>{`Projects`}</h2>
    <ProjectCard title='HAKTIV' link='https://www.haktiv.com/' bg='linear-gradient(to right, #DE6262 0%, #FFB88C 100%)' mdxType="ProjectCard">
    HAKTIV is a bug bounty platform that is the first of its kind in the MENA region. It connects the growing market of tech companies with the finest security researchers and talents in the region to provide security testing on web, mobile, source code and more.
    </ProjectCard>
    <ProjectCard title='12am Thoughts' link='https://12am-thoughts.vercel.app/' bg='linear-gradient(to right, #4CB8C4 0%, #3CD3AD 100%)' mdxType="ProjectCard">
    12 am Thoughts aims to provide a safe space for individuals to express their thoughts & feelings anonymously with no fear of judgement or embarrassment.
    </ProjectCard>
    <ProjectCard title='Dinney' link='https://play.google.com/store/apps/details?id=com.antwansherif.Dinney' bg='linear-gradient(to right, #50C9C3 0%, #96DEDA 100%)' mdxType="ProjectCard">
    Dinney is a application to reserve table in Egypt in different restaurants within a specific location chosen by customer
    </ProjectCard>
    <ProjectCard title='21Farmer' link='https://dashboard.21farmer.com/' bg='linear-gradient(to right, #DE6262 0%, #FFB88C 100%)' mdxType="ProjectCard">
    21Farmer is a cloud based IoT solution that provides data & analytics to farmers to make informed decisions and to enhance land performance and resource utilization.
    </ProjectCard>
    <ProjectCard title='El-Mawkaa' link='http://www.elmawkaa.com' bg='linear-gradient(to right, #DE6262 0%, #FFB88C 100%)' mdxType="ProjectCard">
    El-Mawkaa is a platform that connects all stakeholders in the construction and architecture sector from engineers, investors, contractors and suppliers in the MENA region.
    </ProjectCard>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      