import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import { OutboundLink } from "gatsby-plugin-google-analytics";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <h2>{`Get in touch`}</h2>
    <p>{`Find me on other platforms: `}<OutboundLink style={{
        color: 'var(--theme-ui-colors-primary,#f6ad55)',
        textDecoration: 'none'
      }} href='https://www.linkedin.com/in/antwansherif/' target='_blank' rel='noopener noreferrer' mdxType="OutboundLink">Linkedin</OutboundLink>, <OutboundLink style={{
        color: 'var(--theme-ui-colors-primary,#f6ad55)',
        textDecoration: 'none'
      }} href='https://github.com/AntwanSherif/' target='_blank' rel='noopener noreferrer' mdxType="OutboundLink">GitHub</OutboundLink> & <OutboundLink style={{
        color: 'var(--theme-ui-colors-primary,#f6ad55)',
        textDecoration: 'none'
      }} href='https://twitter.com/antwansherif' target='_blank' rel='noopener noreferrer' mdxType="OutboundLink">{`Twitter`}</OutboundLink></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      