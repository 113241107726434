import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h2>{`About`}</h2>
    <blockquote>
      <p parentName="blockquote">{`A passionate Software Engineer with +8 years of experience who enjoys building professional and friendly web and mobile applications where the end-user experience comes first 🌟`}</p>
    </blockquote>
    <p>{`At Vodafone Intelligent Solutions (_VOIS), I have been recognized as a Subject Matter Expert (SME) in React as well as a Top Performer Employee for 2020/2021.`}</p>
    <p>{`I build applications with modern technologies such as React and React Native, alongside with TypeScript, JavaScript, Node and other tools around the React Ecosystem. 🔥`}</p>
    <p>{`On a personal level, I aspire to be one of the most influencing software engineers in the JavaScript community in Egypt. ⚡️👨🏻‍💻`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      